import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useCollection, useDocumentData} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {useOBSStatus} from "use-obs-studio";
import {Box, Typography} from "@mui/material";
import OBSDomanda from "../components/OBSDomanda";
import {useQuery} from "../custom-hooks/useQuery";

const DomandaAttivaVisual = ({font, campi, prompterBox}) => {
    const {id} = useParams()

    const query = useQuery()
    const pos = query.get('pos')

    const status = useOBSStatus()
    
    const [dettagliConferenza, loading, error] = useDocumentData(
        firestore.doc('tool-press/' + id), {})
    const [domandeConferenza, domandeLoading, domandeError] = useCollection(firestore.collection('tool-press/' + id + '/domande').orderBy('posizione', 'asc'), {})

    return (
        <div style={{
            width: '100%',
            height: '90vh',
            zoom: '1',
            aspectRatio: 'auto',
        }} className={status ? 'obs' : 'obs'}>
            {(dettagliConferenza && domandeConferenza
                    && !loading && !domandeLoading) &&
                <Box style={{width: '100%', height: '100%'}}>
                        <OBSDomanda font={font} campi={campi} alignment={'center'}
                                    dettagliConferenza={dettagliConferenza}
                                    posizioneDomandaAttiva={pos}
                                    domandeConferenza={domandeConferenza}
                                    prompterBox={prompterBox}/>
                </Box>
            }

        </div>
    )
}

export default DomandaAttivaVisual
