import React from "react";
import {Box, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const SetNome = ({nome, handleUpdateNome, handleUpdateLanguage, language}) => {
    const {t} = useTranslation()
    return(
        <Box>
            <TextField
                margin="dense"
                id="title"
                type="text"
                label={t('addConferenza.insertTitle')}
                variant="standard"
                value={nome}
                onChange={handleUpdateNome}
                required
            />
            <br/>
            <Stack direction={"row"} alignItems={"center"} spacing={2} sx={{pt:2}}>
                <Typography>{t('lang')}:</Typography>
                <Select variant='standard' value={language} onChange={handleUpdateLanguage}>
                    <MenuItem value={'it'}>It</MenuItem>
                    <MenuItem value={'en'}>en</MenuItem>
                </Select>
            </Stack>
        </Box>
    )
}

export default SetNome
