import {createTheme, responsiveFontSizes} from "@mui/material";

export const toolpressTheme = responsiveFontSizes(createTheme({
  components: {
      MuiAppBar: {
          styleOverrides:{
              root:{
                  background: 'linear-gradient(90deg, rgba(21,26,55,1) 0%, rgba(10,2,36,1) 100%)',
                  borderBottom: '2px solid #151a37',
                  borderRadius:'0'
              }
          }
      },
      MuiPaper: {
          styleOverrides: {
              root: {
                  borderRadius: '1rem',
                  background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
              },
              gradient: {
                  padding:'.8rem 0',
                  margin:'.8rem 0',
                  border: '2px solid #4C4C7E',
              },
              conference:{
                  border: '2px solid #4C4C7E',
              },
              question: {
                  background: 'rgba(28, 28, 61,1)',
                  border: 'none',
                  padding:'.8rem 0',
                  margin:'.8rem 0',
              },
              tabletMessage:{
                  background: '#6864e6',
                  fontWeight:'bold',
                  borderStartStartRadius:'0'
              }
          },
          variants:[
              {
                  props: {variant:'message'},
                  style: {
                      border: '2px solid #4C4C7E',
                      padding:'.8rem 0',
                      margin:'.8rem 0',
                  },
              },
              {
                  props: {variant: 'message', active:true},
                  style:{
                      borderColor: '#ea4d95',
                      background: 'rgb(48,28,61)'
                  }
              }
          ]
      },
      MuiTabPanel: {
          styleOverrides:{
              root:{
                  padding: '1rem 0.6rem'
              }
          }
      }
  },
    palette:{
      mode:'dark',
        primary:{
          main:'#6864e6'
        },
        error:{
          main:'#ea4d95'
        },
        warning:{
          main:'#fde451'
        },
        success:{
          main:'#79d8a1'
        }
    }
}))
