import React, {useMemo} from "react";
import TabellaDomande from "./TabellaDomande";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {Dialog, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export const TabellaPassate = ({domandeConferenza, dettagliConferenza, id, handleSetOpenPassate, openPassate, azienda, handleSetWait}) => {
    const {t} = useTranslation()

    return (
        <Dialog maxWidth={"xl"} fullWidth open={openPassate} onClose={handleSetOpenPassate}>
            <DialogTitle>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    {t('conference.pastQuestionsTitle')}
                    <IconButton onClick={handleSetOpenPassate}>
                        <Close/>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {<TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} domandeConferenza={domandeConferenza}
                                    tipo={'passate'} azienda={azienda}/>}
            </DialogContent>
        </Dialog>

    )
}
