import React, {useState} from "react";
import {Box, IconButton} from "@mui/material";
import {AddComment} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import {FormAddAnswer} from "./FormAddAnswer";

export const AddAnswer = ({row,tipo}) => {
    const [open, setOpen] = useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
    }

    return (
        <Box component={'span'}>
            <IconButton sx={{color: grey[400], ml: {xs:0.5, md:2}}} onClick={handleSetOpen}>
                <AddComment/>
            </IconButton>
            <FormAddAnswer open={open} handleSetOpen={handleSetOpen} row={row} tipo={tipo}/>
        </Box>
    )
}
