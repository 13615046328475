import React from "react";
import TabellaDomande from "./TabellaDomande";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {Dialog, DialogContent, DialogTitle, IconButton, Stack} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export const TabellaRifiutate = ({dettagliConferenza, id, handleSetOpenRifiutate, openRifiutate, azienda, handleSetWait}) => {
    const [rifiutate, rifiutateLoading, rifiutateError] = useCollection(firestore.collection('tool-press/' + id + '/rifiutate').orderBy('timestamp','asc'), {})
    const {t} = useTranslation()
    return (
        <Dialog maxWidth={"xl"} fullWidth open={openRifiutate} onClose={handleSetOpenRifiutate}>
            <DialogTitle>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    {t('conference.refusedTitle')}
                    <IconButton onClick={handleSetOpenRifiutate}>
                        <Close/>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {(rifiutate && !rifiutateLoading && !rifiutateError) &&
                    <TabellaDomande handleSetWait={handleSetWait} dettagliConferenza={dettagliConferenza} domandeConferenza={rifiutate}
                                    tipo={'rifiutate'} azienda={azienda}/>
                }
            </DialogContent>
        </Dialog>

    )
}
