import React from "react";
import {Box, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const SetRighe = ({righe, handleUpdateRighe}) => {
    const {t} = useTranslation()
    return (
        <Box>
            <Typography sx={{mr: 2}} variant="standard">{t('addConferenza.rowFixed')}</Typography>
            <Select
                variant={"standard"}
                value={righe}
                onChange={handleUpdateRighe}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
            </Select>
        </Box>
    )
}

export default SetRighe
