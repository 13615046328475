import React from "react";
import {Chip, IconButton, InputLabel, Stack, styled, TextField} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {AddCircle} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const ListItem = styled('li')(({theme}) => ({
    margin: theme.spacing(0.5),
}));

const SetColonne = ({onDragEnd, colonne, handleDeleteColonna, handleNuovaColonna, nuovaColonna, addColonna}) => {
    const {t} = useTranslation()
    return (
        <Stack direction={"column"}>
            <InputLabel sx={{mt: 2}} variant="standard">{t('addConferenza.columns')}</InputLabel>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <Stack
                            direction={"row"}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 0.5,
                                m: 0,
                            }}
                            component="ul"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {colonne.map((data, i) => {
                                return (
                                    <Draggable key={data} draggableId={data} index={i}>
                                        {(provided, snapshot) => (
                                            <ListItem key={data}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                            >
                                                <Chip
                                                    label={data}
                                                    onDelete={() => handleDeleteColonna(data)}
                                                />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
            <Stack direction={"row"} alignItems={'baseline'}>
                <TextField
                    margin="dense"
                    id="nuova colonna"
                    label={t('addConferenza.insertColumn')}
                    type="text"
                    variant="standard"
                    onChange={handleNuovaColonna}
                    value={nuovaColonna}
                >
                </TextField>
                <IconButton onClick={addColonna}>
                    <AddCircle/>
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default SetColonne
