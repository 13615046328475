import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, TextareaAutosize} from "@mui/material";
import {firestore} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../../config";

export const FormAddAnswer = ({open, handleSetOpen, row, tipo}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [commento, setCommento] = useState()
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        setCommento(row.commento)
    }, [row])

    const addComment = async () => {
        setUpdating(true)
        await axios.post(`${API_URL}/question/${id}/${row.id}/comment`, {
            collection: ((tipo === 'approvate' || !tipo) ? 'domande' : tipo),
            commento: commento
        })
        handleSetOpen()
    }

    const handleSetComment = (e) => {
        setCommento(e.target.value)
    }

    return (
        <Dialog open={open} onClose={handleSetOpen} maxWidth={'md'} fullWidth>
            <DialogTitle>{t('editDomanda.addComment')}</DialogTitle>
            <DialogContent>
                <TextareaAutosize
                    minRows={7}
                    placeholder={t('message')}
                    type="text"
                    variant="standard"
                    value={commento}
                    style={{width: '100%', resize: 'none'}}
                    onChange={(e) => handleSetComment(e)}
                />
                <Box sx={{mt: 1}}>
                    <Button variant={"contained"} onClick={addComment}>{t('save')}</Button>
                    <Button onClick={handleSetOpen}>{t('close')}</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
