import React, {useState} from "react";
import {Box, Stack, TextField} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import Typography from "@mui/material/Typography";
import {Check, Upload} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const SetImage = ({handleUpdateImage, image}) => {
    const fileTypes = ["JPEG", "PNG", "JPG"];
    const [file, setFile] = useState(null)
    const handleChangeLogo = (file) => {
        handleUpdateImage(file);
        setFile(file)
    };
    const {t} = useTranslation()
    return (
        <Box sx={{py:1}}>
            <Typography variant={"body2"} sx={{mb: 1}}>{t('addConferenza.image')}</Typography>
            <FileUploader
                multiple={false}
                handleChange={handleChangeLogo}
                name="file"
                types={fileTypes}
                children={
                    <Box sx={{
                        width: '100%',
                        p: 2,
                        border: '2px dashed white',
                        borderRadius: '1rem',
                        textAlign: 'center'
                    }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} spacing={4}>
                            {!file ? <Upload sx={{fontSize: '3rem'}}/> : <Check sx={{fontSize: '3rem', color:'green'}}/>}
                            <Typography variant={"body1"}>{!file ?  t('addConferenza.upload'): t('addConferenza.uploaded')+' '+file.name}</Typography>
                        </Stack>
                    </Box>
                }
            />
        </Box>
    )
}

export default SetImage
