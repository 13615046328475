import React, {useEffect, useState} from "react";
import {
    Box,
    Button, capitalize, Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup,
    Select, Stack, styled,
    TextField
} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import firebase from "firebase/compat/app";
import {useTranslation} from "react-i18next";
import {post} from "axios";
import {API_URL} from "../config";

const ListItem = styled('li')(({theme}) => ({
    margin: theme.spacing(0.5),
}));

const FormAddDomanda = ({open, handleSetOpenAdd, colonne, approvazione, dettagliConferenza}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [contenuto, setContenuto] = useState({})
    const [stato, setStato] = useState('daApprovare')
    const [remoto, setRemoto] = useState(false)

    useEffect(() => {
        let cont = {}
        colonne.map(c => cont[c] = '-')
        cont.domanda = '-'
        cont.remoto= false
        setRemoto(false)
        setStato('daApprovare')
        setContenuto(cont)
        return () => {
            setContenuto({})
        }
    }, [open])

    const addDomanda = async () => {
        let cont = contenuto
        await post(`${API_URL}/question/${id}`, {question: {...cont}, stato})
        setContenuto({})
        handleSetOpenAdd()
    }

    const handleUpdateContenuto = (c, event) => {
        let cont = contenuto
        cont[c] = event.target.value
        setContenuto(cont)
    }

    const handleChangeStato = (event) => {
        setStato(event.target.value);
    };

    const handleSetRemoto = (event) => {
        setRemoto(event.target.checked)
    }

    return (
        <Dialog open={open} maxWidth={"sm"} fullWidth onClose={handleSetOpenAdd}>
            <DialogTitle>{t('addDomanda.title')}</DialogTitle>
            <DialogContent>
                {approvazione &&
                    <FormControl fullWidth>
                        <FormLabel id="tipo">{t('addDomanda.type')}</FormLabel>
                        <RadioGroup
                            aria-labelledby="tipo"
                            name="tipo"
                            value={stato}
                            onChange={handleChangeStato}
                        >
                            <FormControlLabel value="daApprovare" control={<Radio/>} label={t('addDomanda.toApprove')}/>
                            <FormControlLabel value="approvata" control={<Radio/>} label={t('addDomanda.approved')}/>
                        </RadioGroup>
                    </FormControl>}
                <FormControl fullWidth sx={{mt:2}}>
                    <FormControlLabel control={<Checkbox checked={remoto}
                                                         onChange={handleSetRemoto} />} label={t('addDomanda.remote')} />
                </FormControl>

                {
                    colonne.map((c,i) =>
                        <div key={i}>
                            <TextField
                                margin="dense"
                                id="title"
                                label={capitalize(c)}
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event) => handleUpdateContenuto(c, event)}
                            />
                        </div>
                    )
                }
                <div>
                    <TextField

                        id="title"
                        label={t('question')}
                        type="text"
                        fullWidth
                        multiline
                        variant="standard"
                        onChange={(event) => handleUpdateContenuto('domanda', event)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSetOpenAdd}>{t('cancel')}</Button>
                <Button variant={"contained"} onClick={addDomanda}>{t('add')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormAddDomanda
