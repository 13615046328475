import React from "react";
import {Box, Button, IconButton, Stack} from "@mui/material";
import {Bolt, Delete, FormatColorText} from "@mui/icons-material";
import {firestore} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../../config";
import {ButtonLoader} from "../shared/ButtonLoader";



export const MessageSettings = ({message_id, active, saveToDb, old, message}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [deleting, setDeleting] = React.useState(false)
    const [changingColor, setChangingColor] = React.useState(false)

    const deleteMessage = async () => {
        setDeleting(true)
        await axios.delete(`${API_URL}/message/${id}/${message_id}`)
        setDeleting(false)
    }

    const changeColor = async (color) => {
        setChangingColor(true)
        await axios.put(`${API_URL}/message/${id}/${message_id}/color`, {color})
        setChangingColor(false)
    }

    const sendMessage = async () => {
        if (!active) {
            saveToDb(message_id)
        }
        await axios.put(`${API_URL}/conference/${id}/activate-message`, {messaggio: active ? '' : message_id})
    }

    const flash = async (bool) => {
        await axios.put(`${API_URL}/message/${id}/${message_id}/flash`, {flashMessage: bool})
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const flashMessage = async (e) => {
        e.preventDefault()
        await flash(true)
        await sleep(3000)
        await flash(false)
    }

    return (
        <Stack justifyContent={"space-between"} direction={"row"}>
            <Box sx={{position:'relative'}}>
                {deleting && <ButtonLoader size={20}/>}
                <IconButton disabled={deleting} size={"small"} onClick={deleteMessage}>
                    <Delete/>
                </IconButton>
            </Box>
            <Box>
                <IconButton size={'small'}  onClick={flashMessage}>
                    <Bolt/>
                </IconButton>
                <IconButton disabled={changingColor || old?.messaggio !== message.messaggio} size={'small'}  onClick={() => changeColor('white')}>
                    <FormatColorText/>
                </IconButton>
                <IconButton disabled={changingColor || old?.messaggio !== message.messaggio} size={'small'} sx={{color:'green'}}>
                    <FormatColorText onClick={() => changeColor('green')}/>
                </IconButton>
                <IconButton disabled={changingColor || old?.messaggio !== message.messaggio} size={'small'} sx={{mr:3, color:'red'}}>
                    <FormatColorText onClick={() => changeColor('red')}/>
                </IconButton>
                <Button onClick={sendMessage} variant={active ? 'contained' : "outlined"} color={'error'} sx={{color: 'white'}}>{active? t('configPrompter.hide') : t('configPrompter.show')}</Button>
            </Box>
        </Stack>
    )
}
