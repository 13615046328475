import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useTranslation} from "react-i18next";

export const SetApprovazione = ({approvazione, handleUpdateApprovazione}) => {
    const {t} = useTranslation()
  return(
      <FormControlLabel
          control={
              <Checkbox checked={approvazione} onChange={handleUpdateApprovazione} name="approvazione"/>
          }
          label={t('addConferenza.needApproval')}
      />
  )
}
