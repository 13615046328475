import React from "react";
import {Box} from "@mui/material";
import {Textfit} from 'react-textfit'


export const MessaggioPrompter = ({preview, dettagliConferenza, previewMessage, prompterMessage, message}) => {
    return (
        <Box sx={{width:'100%', height: '33%'}} className={dettagliConferenza.data().flashMessage && 'flashing'} open={previewMessage || dettagliConferenza.data().messaggio}>
            {message && <Box sx={{width: '100%', height:'100%', opacity: (preview && !prompterMessage) ? '0.3' : '1'}}>
                <Textfit mode={'multi'} min={10} max={140} style={{
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    whiteSpace: 'pre-line',
                    color: message.color
                }}>
                    {message.messaggio}
                </Textfit>
            </Box>}
        </Box>

    )
}
