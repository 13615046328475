import React from "react";
import {Box, Button, Card, TextareaAutosize} from "@mui/material";
import {Replay} from "@mui/icons-material";
import {FastMessages} from "./FastMessages";
import {MessageSettings} from "./MessageSettings";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const MessageField = ({dettagliConferenza, message, handleChangeMessage, saveToDb}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [old, oldLoading, oldError] = useDocument(firestore.doc('tool-press/' + id + '/messaggi/' + message.id))

    return (
        <Card variant={'message'} active={dettagliConferenza.data().messaggio === message.id}>
            <Box sx={{width: '100%', p: 2}}>
                <TextareaAutosize
                    id="title"
                    minRows={4}
                    placeholder={t('message')}
                    type="text"
                    variant="standard"
                    value={message.messaggio}
                    style={{width: '100%', resize: 'none'}}
                    onChange={(event) => handleChangeMessage(event.target.value, message.id)}
                />
                {dettagliConferenza.data().messaggio === message.id &&
                    <Box sx={{width: '100%', textAlign: 'right'}}>
                        <Button variant={"outlined"}
                                disabled={(old && !oldLoading && !oldError) ? old.data().messaggio === message.messaggio : false}
                                color={'error'} startIcon={<Replay/>}
                                onClick={() => saveToDb(message.id)}>
                            {t('configPrompter.update')}
                        </Button>
                    </Box>
                }
                <FastMessages handleChangeMessage={handleChangeMessage} message_id={message.id}/>
                <MessageSettings message_id={message.id}
                                 old={old?.data()}
                                 message={message}
                                 active={dettagliConferenza.data().messaggio === message.id}
                                 saveToDb={saveToDb}/>
            </Box>
        </Card>
    )
}
