import React from 'react'
import Typography from "@mui/material/Typography";
import {Box, Card} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

export const PrompterPreview = ({content}) => {
    const {id} = useParams()
    const {t} = useTranslation()
  return(
      <Box>
          <Typography variant={'h4'} fontWeight={'bold'}>{t('prompter')}</Typography>
          <Card variant={'gradient'} sx={{p: 2}}>
              <Box sx={{width: '100%'}}>
                  <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                      <iframe
                          src={`https://control.toolpress.app/prompter/${id}?min=5&max=20`}
                          frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                          style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                      ></iframe>
                  </div>
              </Box>
              {/*<Prompter preview={true} font={content.font} campi={content.campiPrompter}
                         message={content.message} prompterBox={content.prompterBox}
                         prompterMessage={content.prompterMessage}/>*/}
          </Card>
      </Box>
  )
}
