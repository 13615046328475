import React from "react";
import {Box, Grid} from "@mui/material";
import {Textfit} from "react-textfit";
import {useQuery} from "../../custom-hooks/useQuery";
import {useTranslation} from "react-i18next";

export const BoxDomanda = ({domanda, position, preview, prompterBoxNow, prompterBoxNext, colonne, campiPrompter, campi}) => {
    const query = useQuery()
    const {t,i18n} = useTranslation()

    return (
        <Grid container sx={{opacity: (preview && ((position === 0 && !prompterBoxNow) || (position === 1 && !prompterBoxNext)) ) ? '0.3' : '1', height: prompterBoxNow && prompterBoxNow ? '50%' : '100%'}}
              alignContent={"center"}>
            <Grid item xs={2}
                  style={{border: '1px solid', height: (domanda.commento && domanda.commento !== '') ? '50%' : '100%'}}
                  className={'obs-grid'}>
                <Textfit mode={'multi'} min={10} max={60} style={{
                    width: '100%',
                    height: '100%',
                    color: position === 0 ? 'red' : 'green',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '0 1rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                }}>
                    {position === 0 ? t('configPrompter.now') : t('configPrompter.next')}
                </Textfit>
            </Grid>
            <Grid className="obs-grid" item xs={10}
                  style={{border: '1px solid', height: (domanda.commento && domanda.commento !== '') ? '50%' : '100%'}}
                  alignContent={"center"}>
                <Textfit mode={'multi'} min={query.get('min') ? parseInt(query.get('min')) : 10}
                         max={query.get('max') ? parseInt(query.get('max')) : 40} style={{
                    width: '100%',
                    height: '100%',
                    color: 'inherit',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '0 1rem',
                }}>
                    {domanda && <Box style={{
                        display: 'table-cell',
                        verticalAlign: 'middle'
                    }}>
                        <Box component={'span'} sx={{fontStyle: 'italic'}}>
                            {preview ?
                                colonne.map((c, j) =>
                                    campi.includes(c) &&
                                    <span>
                                {domanda ? domanda[c] : ' '} {j + 1 >= campiPrompter.length ? '' : ' '}
                            </span>)
                                :
                                colonne.map((c, j) =>
                                    campiPrompter.includes(c) &&
                                    <span>
                                {domanda ? domanda[c] : ' '} {j + 1 === campiPrompter.length ? '' : ' '}
                            </span>)
                            }
                        </Box>
                        {domanda.domanda !== undefined ? '- ' + domanda.domanda : ''}
                    </Box>}
                </Textfit>
            </Grid>
            {(domanda.commento && domanda.commento !== '') && <Grid item xs={12} style={{height: '50%'}}>
                <Textfit
                    mode={'multi'} min={query.get('min') ? parseInt(query.get('min')) : 10}
                    max={query.get('max') ? parseInt(query.get('max')) : 40} style={{
                    width: '100%',
                    height: '100%',
                    color: 'inherit',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '0 1rem'
                }}>
                    <b style={{color: '#FFFD54'}}>{t('configPrompter.info')}</b> {domanda.commento}
                </Textfit>
            </Grid>}
        </Grid>
    )
}
