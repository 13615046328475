import React from "react";
import {Chip, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";

export const FastMessages = ({handleChangeMessage,message_id}) => {
    const {t} = useTranslation()
    const mess=[t('configPrompter.fast1'), t('configPrompter.fast2'),t('configPrompter.fast3'),t('configPrompter.fast4'),]
  return(
      <Stack direction={'row'} spacing={1} sx={{width:'100%', py:1}}>
          {mess.map((m, index) =>
              <Chip key={index} label={m} onClick={() => handleChangeMessage(m, message_id)}/>
          )}
      </Stack>
  )
}
