import React, {useEffect, useState} from "react";
import {Box, Button, Card, CircularProgress, Container, Snackbar, Stack, TextField, Typography} from "@mui/material";
import logo from '../assets/logo_toolpress.png'
import {firebase, auth} from "../firebase/clientApp";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CustomAlert from "../components/CustomAlert";

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const logIn = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/', {replace: true})
            })
            .catch((error) => {
                setError(true)
            });
        setLoading(false)
    }

  return(
      <Box sx={{textAlign:'center', width:'100%', mt:10}}>
          <img src={logo} style={{width:'100%',maxWidth:'17.563rem'}}/>
          <CustomAlert message={"Le credenziali sono errate"} severity={'error'} open={error} setOpen={setError}/>
          <Container maxWidth={'sm'}>
              <Typography variant={"h4"} fontWeight={'bold'} sx={{my: 5}} textTransform={'uppercase'} color={'white'}>
                  {t('login.title')}
              </Typography>
              <Card variant={'gradient'}>
                  <form style={{padding: '1rem 1rem', textAlign: 'left'}} onSubmit={logIn}>
                      <Stack direction={"column"} spacing={4}>
                          <TextField variant={'standard'} type={'email'} onChange={(e) => setEmail(e.target.value)} label={t('login.email')}/>
                          <TextField type='password' variant={'standard'} onChange={(e) => setPassword(e.target.value)} label={t('login.password')}/>
                          <Box sx={{textAlign: 'center'}}>
                              {loading ? <CircularProgress/> : <Button sx={{my: 2}} variant={"contained"} type={"submit"}>
                                  {t('login.enter')}
                              </Button>}
                          </Box>
                      </Stack>
                  </form>
              </Card>
          </Container>
      </Box>
  )
}
