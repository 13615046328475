import React from "react";
import {useParams} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import TabellaPrompter from "../components/TabellaPrompter";
import {useOBSStatus} from "use-obs-studio";
import {MessaggioPrompter} from "../components/MessaggioPrompter";
import {Box} from "@mui/material";

const Prompter = ({preview, font, campi, message, prompterBox, prompterMessage}) => {
    const {id} = useParams()
    const status = useOBSStatus()
    const [dettagliConferenza, loading, error] = useDocument(firestore.doc('tool-press/' + id), {})
    const [domandeConferenza, domandeLoading, domandeError] = useCollection(firestore.collection('tool-press/' + id + '/domande').orderBy('posizione', 'asc'), {})
    const [messages, messageLoading, messageError] = useCollection(firestore.collection('tool-press/' + id + '/messaggi'), {})

    return (
        <div style={{
            width: '100%',
            height: preview ? 'auto' : '100vh',
            zoom: preview ? '0.32' : '1',
            aspectRatio: preview ? '16 / 9' : 'auto'
        }} className={status ? 'obs' : ''}>
            {(dettagliConferenza && domandeConferenza && !loading && !domandeLoading) &&
                <Box style={{width: '100%', height: '100%'}}>
                    {(dettagliConferenza.data().prompterBoxNow || dettagliConferenza.data().prompterBoxNext || preview) &&
                        <TabellaPrompter preview={preview} font={font} campi={campi}
                                         dettagliConferenza={dettagliConferenza}
                                         prompterBoxNow={dettagliConferenza.data().prompterBoxNow}
                                         prompterBoxNext={dettagliConferenza.data().prompterBoxNext}
                                         prompterMessage={dettagliConferenza.data().prompterMessage}
                                         domandeConferenza={domandeConferenza} prompterBox={prompterBox}/>}
                    {((dettagliConferenza.data().prompterMessage || preview) && !messageLoading && !messageError) &&
                        <MessaggioPrompter preview={preview} dettagliConferenza={dettagliConferenza}
                                           previewMessage={message} prompterMessage={prompterMessage}
                                           message={messages.docs.filter(d => d.id === dettagliConferenza.data().messaggio)[0]?.data()}
                        />}
                </Box>
            }

        </div>
    )
}

export default Prompter
