import React, {useEffect} from "react";
import {Box, Grid} from "@mui/material";
import {Textfit} from 'react-textfit'
import {useTranslation} from "react-i18next";
import {useQuery} from "../custom-hooks/useQuery";
import {BoxDomanda} from "./Prompter/BoxDomanda";

const TabellaPrompter = ({
                             dettagliConferenza,
                             domandeConferenza,
                             preview,
                             prompterMessage,

                             campi,
                             prompterBox
                         }) => {
    const query = useQuery()
    const {t, i18n} = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(dettagliConferenza.data().language)
    }, [dettagliConferenza])
    const BoxDomande = () => {
        let s = []
        domandeConferenza.docs.map(d => s.push(d.data()))
        let domande = s.slice(dettagliConferenza.data().domandaAttuale, 2 + dettagliConferenza.data().domandaAttuale)
        return (
            <Box sx={{height:'100%'}}>
                {dettagliConferenza.data().prompterBoxNow && domande[0] &&
                    <BoxDomanda position={0} domanda={domande[0]} preview={preview}
                                campi={campi}
                                prompterBoxNow={dettagliConferenza.data().prompterBoxNow ?? false}
                                prompterBoxNext={dettagliConferenza.data().prompterBoxNext ?? false}
                                colonne={dettagliConferenza.data().colonne ?? false}
                                campiPrompter={dettagliConferenza.data().campiPrompter ?? false}/>}
                {dettagliConferenza.data().prompterBoxNext && domande[1] &&
                    <BoxDomanda position={1} domanda={domande[1]} preview={preview}
                                campi={campi}
                                prompterBoxNow={dettagliConferenza.data().prompterBoxNow ?? false}
                                prompterBoxNext={dettagliConferenza.data().prompterBoxNext ?? false}
                                colonne={dettagliConferenza.data().colonne ?? false}
                                campiPrompter={dettagliConferenza.data().campiPrompter ?? false}/>
                }
            </Box>
        )
    }

    return (
        <Box style={{height: '66%'}}>
            {domandeConferenza.docs.length > 0 &&
                <Box sx={{p: preview ? 0 : 2, height: '100%'}}>
                    <Box sx={{border: '1px solid white', height: '100%'}}>
                        <BoxDomande/>
                    </Box>

                </Box>}
        </Box>

    )
}

export default TabellaPrompter
