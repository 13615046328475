import React from "react";
import {MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

export const LangSwitch = () => {
    const {t, i18n} = useTranslation()
    return (
        <Select variant='standard' sx={{width: '4rem'}} value={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}>
            <MenuItem value={'it'}>It</MenuItem>
            <MenuItem sx={{}} value={'en'}>En</MenuItem>
        </Select>
    )
}
