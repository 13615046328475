import React, {useEffect, useState} from "react";
import {Box, Grow, Typography} from "@mui/material";
import {Textfit} from 'react-textfit'
import {useQuery} from "../custom-hooks/useQuery";
import {isEmpty} from "lodash";

const OBSDomanda = ({dettagliConferenza, domandeConferenza, posizioneDomandaAttiva}) => {
    const toonBox = dettagliConferenza.currentQuestionStyle === 'toonBox'
    const plain = dettagliConferenza.currentQuestionStyle === 'plain'
    const animation = dettagliConferenza.currentQuestionTransition

    const [trigger, setTrigger] = useState(animation)

    useEffect(() => {
        if(animation)
            setTrigger(false)
            setTimeout(() => {
                setTrigger(true)
            }, 1200)
    }, [posizioneDomandaAttiva, dettagliConferenza.domandaAttuale])


    const query = useQuery()
    const BoxDomande = () => {
        const preview = query.get('preview')
        let s = []
        domandeConferenza.docs.map(d => s.push(d.data()))
        let domande = s.slice(
            !!posizioneDomandaAttiva ? (posizioneDomandaAttiva - 1) : dettagliConferenza.domandaAttuale,
            !!posizioneDomandaAttiva ? (posizioneDomandaAttiva) : (dettagliConferenza.domandaAttuale + 1)
        )
        let dom = []
        domande.map((d, i) => {
            const showColLabel = !isEmpty(dettagliConferenza.campiCurrentQuestion)
            let columnsLabel = ""
            if(showColLabel)
                dettagliConferenza.campiCurrentQuestion.map((colonna) => {
                    columnsLabel += ` ${d[colonna]}`
                })

                dom.push(
                    <Box key={i} style={{
                        width: !plain ? '95%' : '100%',
                        height: !plain ? '90%' : '100%'
                    }}>
                        {trigger && showColLabel &&
                            <Typography fontStyle={'italic'} fontSize={90}
                                        lineHeight={1}
                                 sx={{
                                     color: query.get('columnsColor') ? `#${query.get('columnsColor')}` : '#fff'
                                 }}>
                            {columnsLabel}
                        </Typography>}
                        <div className={toonBox ? "triangoloEq" : ''}/>
                    <Box key={i} pb={3} px={2}
                         sx={{
                             background: plain ? 'transparent' : preview ? 'red' : 'white',
                             boxShadow: !plain ? `4px 4px 8px 0px #121212` : '',
                             width: '100%', height: '100%',
                             borderRadius: !plain ? '2rem' : ''
                         }}>
                        <Textfit mode={'multi'} min={query.get('min') ? parseInt(query.get('min')) : 50}
                                 max={query.get('max') ? parseInt(query.get('max')) : 80} className={"OBS-domanda-container"}>
                            {d && <Box className={"OBS-domanda"} style={{
                                color: query.get('color') ? `#${query.get('color')}` : !plain ? 'black' : '#fff',
                            }}>
                                {d.domanda !== undefined && trigger
                                    ? d.domanda : ''}
                            </Box>}
                        </Textfit>
                    </Box>
                    </Box>
                )
            }
        )
        return dom
    }

    return (
        <Box height={'100%'}>
            {domandeConferenza.docs.length > 0 &&
                <Grow in={trigger} exit={true} timeout={900}
                      style={{transformOrigin: '0 0'}}>
                    <Box height={'100%'}>
                        <BoxDomande/>
                    </Box>
                </Grow>
            }
        </Box>

    )
}

export default OBSDomanda
