import Marquee from "react-fast-marquee";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useQuery} from "../custom-hooks/useQuery";

export const BannerRssFeed = () => {
    const {id} = useParams()

    const query = useQuery()
    const width = query.get('width')
    const spacing = query.get('spacing')
    const speed = query.get('speed')
    const dotSeparator = query.get('dot')

    const [domandeConferenza, domandeLoading, domandeError] = useCollection(firestore.collection('tool-press/' + id + '/domande').orderBy('posizione','asc'), {})

    return (
        <div id={"bannerContainer"} style={{width: !!width ? `${width}px` : 'inherit'}}>
            <Marquee speed={speed || 50}>
                {domandeConferenza?.docs.map((doc, index) => (
                    <div id={"messageContainer"} style={{display: 'flex', alignItems: 'center'}}>
                        <MessageFeed domanda={doc.data().domanda}/>
                        {<Box width={`${spacing || 50}px`}>
                            {dotSeparator === 'true' &&
                                <Typography textAlign={'center'}>
                                    •
                                </Typography>}
                        </Box>}
                    </div>
                ))}
            </Marquee>
        </div>
    )
}

const MessageFeed = ({domanda}) => {
    return <p id={"message"}>
            {domanda}
        </p>
}