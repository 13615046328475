import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Box, Button} from "@mui/material";
import {firestore} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Add } from "@mui/icons-material";
import {MessageField} from "./MessageField";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../../config";

export const PrompterMessages = ({dettagliConferenza}) => {
    const {t} = useTranslation()
    const {id} = useParams()
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [adding, setAdding] = useState(false)
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        setLoading(true)
        getMessages().then(()=> setLoading(false))
    },[])

    const getMessages = async () => {
        return firestore.collection('tool-press/' + id + '/messaggi').orderBy('posizione', 'asc')
            .onSnapshot((snap) => {
                let m = []
                snap.forEach((doc) => m.push({id: doc.id, ...doc.data()}))
                setMessages(m)
            })
    }

    const addMessage = async () => {
        setAdding(true)
        await axios.post(`${API_URL}/message/${id}`, {})
        setAdding(false)
    }

    const handleChangeMessage = (content, message_id) => {
        const messaggi = messages
        const index = messages.findIndex(m => m.id === message_id)
        messaggi[index].messaggio = content
        setMessages([...messaggi])
    }

    const saveToDb = async (message_id) => {
        let content = ''
        const messaggi = messages
        const index = messages.findIndex(m => m.id === message_id)
        content = messaggi[index].messaggio
        setUpdating(true)
        await axios.put(`${API_URL}/message/${id}/${message_id}`, {messaggio: content})
        setUpdating(false)
    }

    return (
        <Box>
            <Typography variant={'h4'} fontWeight={'bold'}>{t('messages')}</Typography>
            <Box>
                {(!loading && messages.length>0 && dettagliConferenza) &&
                    messages.map((m, i) =>
                        <MessageField key={i} dettagliConferenza={dettagliConferenza} message={m} handleChangeMessage={handleChangeMessage} saveToDb={saveToDb}/>)
                }
                <Box sx={{width: '100%', textAlign: 'center', pt: 2}}>
                    <Button onClick={addMessage} variant={"outlined"} sx={{color: 'white'}} startIcon={<Add/>}>{t('configPrompter.addMessage')}</Button>
                </Box>
            </Box>
        </Box>
    )
}
