import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, TextField, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {db, firestore} from "../firebase/clientApp";
import {useCollection} from "react-firebase-hooks/firestore";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../config";

export const ClearAllForm = ({open, handleClose}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [listaDomande, loading, error] = useCollection(firestore.collection('tool-press/'+id+'/domande'))
    const [listaDaApprovare, loadingDaApprovare, errorDaApprovare] = useCollection(firestore.collection('tool-press/'+id+'/daApprovare'))
    const [listaRifiutate, loadingRifiutate, errorRifiutate] = useCollection(firestore.collection('tool-press/'+id+'/rifiutate'))
    const [text, setText] = useState('')

    const handleSetText = (e) => {
        setText(e.target.value)
    }

    const clearAll = async () => {
        await axios.delete(`${API_URL}/conference/${id}/clear-all`)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Typography variant={"h5"} fontWeight={'bold'} textAlign={'center'}>{t('clearAll.title')}</Typography>
                <Typography sx={{my:2, opacity:0.8}} variant={"body2"}>{t('clearAll.subtitle')} <b>DELETE</b></Typography>
                <TextField placeholder={'DELETE'} fullWidth variant={"standard"} onChange={handleSetText}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {t('back')}
                </Button>
                <Button onClick={clearAll} disabled={text!=='DELETE'} color={"error"} variant={"contained"}>
                    {t('delete')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
