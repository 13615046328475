import React, {useEffect, useState} from "react";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Slide,
    Toolbar
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Close} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import {PrompterPreview} from "./FormConfiguraPrompter/PrompterPreview";
import {PrompterOptions} from "./FormConfiguraPrompter/PrompterOptions";
import {PrompterMessages} from "./FormConfiguraPrompter/PrompterMessages";
import {useTranslation} from "react-i18next";
import CurrentQuestionPreview from "./FormConfiguraPrompter/CurrentQuestionPreview";
import CurrentQuestionOptions from "./FormConfiguraPrompter/CurrentQuestionOptions";
import axios from "axios";
import {API_URL} from "../config";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FormConfiguraPrompter = ({openPromp, handleSetOpenPromp, dettagliConferenza, domandeConferenza}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [contentPrompter, setContentPrompter] = useState({
        campiPrompter: [],
        prompterBoxNow: null,
        prompterBoxNext: null,
        prompterMessage: null
    })

    const [contentCurrentQuestion, setContentCurrentQuestion] = useState({
        campiCurrentQuestion: [],
        currentQuestionStyle: null,
        currentQuestionTransition: true
    })

    const [changes, setChanges] = useState(false)

    useEffect(() => {
        setContentCurrentQuestion({
            campiCurrentQuestion: dettagliConferenza.data()?.campiCurrentQuestion || [],
            currentQuestionStyle: dettagliConferenza.data()?.currentQuestionStyle || 'plain',
            currentQuestionTransition: dettagliConferenza.data()?.currentQuestionTransition
        })
        setContentPrompter({
            campiPrompter: dettagliConferenza.data().campiPrompter,
            prompterMessage: dettagliConferenza.data().prompterMessage,
            prompterBoxNow: dettagliConferenza.data().prompterBoxNow,
            prompterBoxNext: dettagliConferenza.data().prompterBoxNext
        })
    }, [dettagliConferenza])

    const updatePrompter = async () => {
        let cont = contentPrompter
        await axios.put(`${API_URL}/conference/${id}/update-prompter`, {
            campiPrompter: cont.campiPrompter,
            prompterBoxNow: cont.prompterBoxNow,
            prompterBoxNext: cont.prompterBoxNext,
            prompterMessage: cont.prompterMessage
        })
        setChanges(false)
    }

    const setPrompterMessage = (event) => {
        setContentPrompter({
            ...contentPrompter,
            prompterMessage: event.target.checked
        })
        setChanges(true)
    }

    const setPrompterBoxNow = (event) => {
        setContentPrompter({
            ...contentPrompter,
            prompterBoxNow: event.target.checked
        })
        setChanges(true)
    }

    const setPrompterBoxNext = (event) => {
        setContentPrompter({
            ...contentPrompter,
            prompterBoxNext: event.target.checked
        })
        setChanges(true)
    }

    const handleChangeCampi = (event) => {
        let campi = contentPrompter.campiPrompter
        if (event.target.checked) {
            campi.push(event.target.name)
            setContentPrompter({
                ...contentPrompter,
                campiPrompter: [...campi]
            });
        } else {
            let index = campi.indexOf(event.target.name)
            if (index > -1) {
                campi.splice(index, 1)
                setContentPrompter({
                    ...contentPrompter,
                    campiPrompter: [...campi]
                });
            }
        }
        setChanges(true)
    }

    const updateCurrentQuestionOpts = async () => {
        let cont = contentCurrentQuestion
        await axios.put(`${API_URL}/conference/${id}/update-question-visual`, {
            campiCurrentQuestion: cont.campiCurrentQuestion,
            currentQuestionStyle: cont.currentQuestionStyle,
            currentQuestionTransition: cont.currentQuestionTransition
        })
    }

    const setCurrentQuestionStyle = (event) => {
        setContentCurrentQuestion({
            ...contentCurrentQuestion,
            currentQuestionStyle: event.target.value
        })
        //setChanges(true)
    }

    const setCurrentQuestionTransition = (event) => {
        setContentCurrentQuestion({
            ...contentCurrentQuestion,
            currentQuestionTransition: event.target.checked
        });
    }

    const handleChangeCampiCurrentQuestion = (event) => {
        let campi = contentCurrentQuestion.campiCurrentQuestion
        if (event.target.checked) {
            campi.push(event.target.name)
            setContentCurrentQuestion({
                ...contentCurrentQuestion,
                campiCurrentQuestion: [...campi]
            });
        } else {
            let index = campi.indexOf(event.target.name)
            if (index > -1) {
                campi.splice(index, 1)
                setContentCurrentQuestion({
                    ...contentCurrentQuestion,
                    campiCurrentQuestion: [...campi]
                });
            }
        }
    }

    return (
        <Dialog fullScreen open={openPromp} onClose={handleSetOpenPromp} TransitionComponent={Transition}
                sx={{border: 'none'}}>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleSetOpenPromp}
                        aria-label="close"
                    >
                        <Close/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {t('configPrompter.title')}
                    </Typography>
                    <Button onClick={handleSetOpenPromp}>{t('configPrompter.cancel')}</Button>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <PrompterPreview content={contentPrompter}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PrompterOptions changes={changes} content={contentPrompter} updatePrompter={updatePrompter}
                                         setPrompterMessage={setPrompterMessage} setPrompterBoxNow={setPrompterBoxNow}
                                         setPrompterBoxNext={setPrompterBoxNext}
                                         handleChangeCampi={handleChangeCampi} dettagliConferenza={dettagliConferenza}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PrompterMessages dettagliConferenza={dettagliConferenza}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CurrentQuestionPreview/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CurrentQuestionOptions content={contentCurrentQuestion}
                                                updateCurrentQuestionOpts={updateCurrentQuestionOpts}
                                                handleChangeCampiCurrentQuestion={handleChangeCampiCurrentQuestion}
                                                setCurrentQuestionStyle={setCurrentQuestionStyle}
                                                setCurrentQuestionTransition={setCurrentQuestionTransition}
                                                dettagliConferenza={dettagliConferenza}
                                                changes={changes}/>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default FormConfiguraPrompter
