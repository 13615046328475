import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    Radio,
    RadioGroup,
    Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

const CurrentQuestionOptions = ({
                                    content,
                                    updateCurrentQuestionOpts,
                                    handleChangeCampiCurrentQuestion,
                                    setCurrentQuestionStyle,
                                    setCurrentQuestionTransition,
                                    dettagliConferenza
                                }) => {
    const {t} = useTranslation()

    return (
        <Box>
            <Typography variant={'h4'} fontWeight={'bold'}>{t('options')}</Typography>
            <Card variant={'gradient'} sx={{p: 2}}>
                <Stack direction={'column'} justifyContent={'space-evenly'} spacing={3}>
                    <Box>
                        <InputLabel variant="standard">{t('configCurrentQuestion.fields')}</InputLabel>
                        <FormGroup row={true}>
                            {dettagliConferenza.data().colonne.map((c, i) =>
                                <FormControlLabel key={i}
                                                  control={<Checkbox name={c}
                                                                     onChange={handleChangeCampiCurrentQuestion}
                                                                     checked={content?.campiCurrentQuestion?.includes(c)}
                                                  />}
                                                  label={c}/>
                            )}
                        </FormGroup>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel
                                id="demo-controlled-radio-buttons-group">{t('configCurrentQuestion.style.configStyle')}</FormLabel>
                            <FormGroup row={true}>
                                <RadioGroup
                                    row
                                    aria-labelledby="row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={content.currentQuestionStyle}
                                    onChange={setCurrentQuestionStyle}
                                >
                                    <FormControlLabel value="toonBox" control={<Radio/>}
                                                      label={t('configCurrentQuestion.style.toonBox')}/>
                                    <FormControlLabel value="box" control={<Radio/>}
                                                      label={t('configCurrentQuestion.style.box')}/>
                                    <FormControlLabel value="plain" control={<Radio/>}
                                                      label={t('configCurrentQuestion.style.plain')}/>
                                </RadioGroup>
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Box>
                        <InputLabel variant="standard">{t('configCurrentQuestion.fields')}</InputLabel>
                        <FormControlLabel control={<Checkbox name={'transition'}
                                                             onChange={setCurrentQuestionTransition}
                                                             checked={content?.currentQuestionTransition}
                        />}
                                          label={"Transizione"}/>
                    </Box>
                </Stack>
                <Box sx={{pt: 2}}>
                    <Button variant={"contained"}
                            onClick={updateCurrentQuestionOpts}>{t('save')}</Button>
                </Box>
            </Card>
        </Box>
    );
}

export default CurrentQuestionOptions