import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, CardActionArea, CardActions, CircularProgress, IconButton, Stack} from '@mui/material';
import {Link} from "react-router-dom";
import {Archive, DeleteForever, Edit, Unarchive} from "@mui/icons-material";
import {useState} from "react";
import FormCancellaConferenza from "./FormCancellaConferenza";
import FormEditConferenza from "./FormEditConferenza";
import {firestore} from "../firebase/clientApp";
import axios from "axios";
import {API_URL} from "../config";

export default function CardConferenza({conferenza}) {
    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [archiving, setArchiving] = useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
    }

    const handleSetEditOpen = () => {
        setEditOpen(!editOpen)
    }

    const archiveConference = async () => {
        setArchiving(true)
        await axios.put(`${API_URL}/conference/${conferenza.id}/archive`)
        setArchiving(false)
    }

    return (
        <Card width={'100%'} sx={{minHeight:'270px'}} variant={'conference'}>
            <CardActionArea  to={'/conferenza/'+conferenza.id} component={Link}>
                <CardMedia
                    component="img"
                    height="140"
                    image={conferenza.data().image}
                    alt="logo"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {conferenza.data().nome}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions sx={{px:2}}>
                <Stack sx={{width:'100%'}} direction={'row'} justifyContent={"space-between"}>
                    <Stack direction={"row"} spacing={1}>
                        <IconButton aria-label="edit" onClick={handleSetEditOpen}>
                            <Edit/>
                        </IconButton>
                        {archiving ? <CircularProgress size={30}/> : <IconButton aria-label="archivia" onClick={archiveConference}>
                            {conferenza.data().archived ? <Unarchive/> : <Archive/>}
                        </IconButton>}
                    </Stack>
                    <IconButton aria-label="delete" color={"error"} onClick={handleSetOpen}>
                        <DeleteForever/>
                    </IconButton>
                </Stack>
            </CardActions>
            <FormCancellaConferenza open={open} handleSetOpen={handleSetOpen} nome={conferenza.data().nome} id={conferenza.id}/>
            <FormEditConferenza open={editOpen} conferenza={conferenza} handleSetEditOpen={handleSetEditOpen}/>
        </Card>
    );
}
