import React, {useEffect, useState} from "react";
import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import {firebase, firestore} from "../firebase/clientApp";
import {
    Box,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import CardConferenza from "../components/CardConferenza";
import AddConferenza from "../components/AddConferenza";
import Header from "../components/Header";

import {Login} from "./Login";

function Home({user}) {
    const docRef = firestore.collection('tool-press').orderBy('timestamp');
    const [listaConferenze, loading, error] = useCollection(docRef)
    const [archived, setArchived] = useState(false)

    const CardList = () => {
        let cards = []
        if (!loading && !error && listaConferenze) {
            cards = listaConferenze.docs.filter(c => archived ? c.data().archived : !c.data().archived).map(l =>
                <Grid xs={12} md={6} lg={4} item key={l.id}>
                    <CardConferenza conferenza={l}/>
                </Grid>
            )
        }
        return cards
    }

    const handleChangeArchived = (event, newArchived) => {
        setArchived(newArchived);
    };

    return (
        <div>
            <Header user={user}/>
            <Container sx={{my: 4}}>
                <Stack sx={{width: '100%'}} justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                    <Typography variant={"h4"} fontWeight={'bold'} sx={{pb: 2}}>
                        Lista conferenze
                    </Typography>
                    {!archived && <AddConferenza/>}
                </Stack>
                <Divider/>
                <Box sx={{py: 2}}>
                    <ToggleButtonGroup
                        value={archived}
                        exclusive
                        onChange={handleChangeArchived}
                        aria-label="Platform"
                    >
                        <ToggleButton value={false}>Attive</ToggleButton>
                        <ToggleButton value={true}>Archiviate</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {loading ? <CircularProgress/>
                    :
                    <Grid container spacing={2}>
                        <CardList/>
                    </Grid>
                }
            </Container>
        </div>

    )

}

export default Home
