import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Box, Button, Card, Checkbox, FormControlLabel, FormGroup, InputLabel, Stack, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

export const PrompterOptions = ({changes, content, setPrompterBoxNow, setPrompterBoxNext, setPrompterMessage, handleChangeCampi, updatePrompter, dettagliConferenza}) => {
    const {t} = useTranslation()

    return (
        <Box>
            <Typography variant={'h4'} fontWeight={'bold'}>{t('options')}</Typography>
            <Card variant={'gradient'} sx={{p: 2}}>
                <Stack direction={'column'} justifyContent={'space-evenly'} spacing={3}>
                    <Box>
                        <InputLabel variant="standard">{t('configPrompter.fields')}</InputLabel>
                        <FormGroup row={true}>
                            {dettagliConferenza.data().colonne.map((c, i) =>
                                <FormControlLabel key={i}
                                                  control={<Checkbox name={c} onChange={handleChangeCampi}
                                                                     checked={content.campiPrompter.includes(c)}/>}
                                                  label={c}/>
                            )}
                        </FormGroup>
                    </Box>
                    <Box>
                        <InputLabel variant="standard">{t('configPrompter.sections')}</InputLabel>
                        <FormGroup row={true}>
                            <FormControlLabel control={<Checkbox name={'box'} onChange={setPrompterBoxNow}
                                                                 checked={content.prompterBoxNow}/>}
                                              label={t('configPrompter.showNow')}/>
                            <FormControlLabel control={<Checkbox name={'box'} onChange={setPrompterBoxNext}
                                                                 checked={content.prompterBoxNext}/>}
                                              label={t('configPrompter.showNext')}/>
                            <FormControlLabel
                                control={<Checkbox name={'message'} onChange={setPrompterMessage}
                                                   checked={content.prompterMessage}/>}
                                label={t('configPrompter.showMessage')}/>
                        </FormGroup>
                    </Box>
                </Stack>
                <Box sx={{pt: 2}}>
                    <Button disabled={!changes} variant={"contained"}
                            onClick={updatePrompter}>{t('configPrompter.save')}</Button>
                </Box>
            </Card>
        </Box>
    )
}
