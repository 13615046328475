import React, {useEffect, useState} from "react";
import {
    Button, capitalize, Dialog,
    DialogActions,
    DialogContent,
    Box,
    DialogTitle, Stack, TextField, CircularProgress
} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../config";


const FormAddConferenza = ({open, handleSetOpen, colonne, domanda, tipo}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [contenuto, setContenuto] = useState({})
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        let cont = {}
        colonne.map(c => cont[c] = domanda[c])
        setContenuto({...cont, domanda:domanda.domanda})
    },[domanda,open])

    const editDomanda = async () => {
        setSaving(true)
        let cont = contenuto
        await axios.put(`${API_URL}/question/${id}/${domanda.id}`, {question: cont, collection: tipo ? (tipo === 'approvate' ? 'domande' : tipo) : 'domande'})
        /*let destination = tipo ? (tipo === 'approvate' ? 'domande' : tipo) : 'domande'
        const database = firestore.collection('tool-press/' + id + '/' + destination)
        database.doc(domanda.id).update(cont)
            .then()*/
        setSaving(false)
        handleSetOpen()
    }

    const handleUpdateContenuto = (event, c) => {
        setContenuto({...contenuto, [c]: event.target.value})
    }

    return(
        <Dialog open={open} fullWidth maxWidth={'sm'} onClose={handleSetOpen}>
            <DialogTitle>{t('editDomanda.title')}</DialogTitle>
            <DialogContent>
                {
                    Object.keys(contenuto).map((c, index) =>
                        <div key={index}>
                            <TextField
                                margin="dense"
                                id="title"
                                label={capitalize(c)}
                                type="text"
                                multiline
                                fullWidth
                                variant="standard"
                                value={contenuto[c]}
                                onChange={(event) => handleUpdateContenuto(event, c)}
                            />
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Stack direction={'row'} spacing={2}>
                    <Box>
                        <Button onClick={handleSetOpen}>{t('back')}</Button>
                    </Box>
                    <Box position={'relative'}>
                        {saving && <Stack sx={{width: '100%', height: '100%', position: 'absolute'}} justifyContent={'center'}
                                alignItems={'center'}>
                            <CircularProgress size={30}/>
                        </Stack>}
                        <Button disabled={saving} variant={"contained"} onClick={editDomanda}>{t('save')}</Button>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

export default FormAddConferenza
