import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import {ArrowDownward, ArrowUpward, DeleteForever} from "@mui/icons-material";
import {firestore} from "../firebase/clientApp";
import {ClearAllForm} from "./ClearAllForm";
import SetImage from "./FormAddConferenza/SetImage";
import axios from "axios";
import SetNome from "./FormAddConferenza/SetNome";
import SetDomande from "./FormAddConferenza/SetDomande";
import SetRighe from "./FormAddConferenza/SetRighe";
import SetPin from "./FormAddConferenza/SetPin";
import SetFonts from "./FormAddConferenza/SetFonts";
import {useTranslation} from "react-i18next";
import {API_URL} from "../config";
import SetColonne from "./FormAddConferenza/SetColonne";

const FormEditConferenza = ({open, handleSetEditOpen, conferenza}) => {
    const [state, setState] = useState({
        nome: conferenza.data().nome,
        pin: conferenza.data().pin,
        righeAgenzia: conferenza.data().righeAgenzia,
        image: conferenza.data().image,
        haDomande: conferenza.data().haDomande,
        fontTabella: conferenza.data().fontTabella,
        colonne: conferenza.data().colonne,
        fontAzienda: conferenza.data().fontAzienda,
        fontTablet: conferenza.data().fontTablet,
        domandePin: conferenza.data().domandePin,
        messaggioAgenzia: conferenza.data().messaggioAgenzia,
        language: conferenza.data().language
    })

    const [nuovaColonna, setNuovaColonna] = useState('')

    const [loading, setLoading] = useState(false)

    const {t} = useTranslation()

    const [imageFile, setImageFile] = useState(null);

    const [deleteAll, setDeleteAll] = useState(false)

    const [advanced, setAdvanced] = useState(false)

    const updateConferenza = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('file', imageFile);
        if (imageFile !== null) {
            await axios({
                url: `${API_URL}/conference/${conferenza.id}/logo`,
                method: 'POST',
                data: formData,
            }).then()
        }
        await axios.put(`${API_URL}/conference/${conferenza.id}`, {
            nome: state.nome,
            righeAgenzia: state.righeAgenzia,
            pin: state.pin,
            haDomande: state.haDomande,
            fontTabella: state.fontTabella,
            fontAzienda: state.fontAzienda,
            fontTablet: state.fontTablet,
            domandePin: state.haDomande ? state.domandePin : false,
            messaggioAgenzia: state.messaggioAgenzia,
            language: state.language,
            colonne: state.colonne
        })
        setLoading(false)
        handleSetEditOpen()
    }

    const handleUpdateNome = (e) => {
        setState({
            ...state,
            nome: e.target.value
        })
    }

    const handleUpdateImage = (file) => {
        setImageFile(file)
    }

    const handleUpdateFontTabella = (e) => {
        setState({
            ...state,
            fontTabella: e.target.value
        })
    }

    const handleUpdateFontAzienda = (e) => {
        setState({
            ...state,
            fontAzienda: e.target.value
        })
    }

    const handleUpdateFontTablet = (e) => {
        setState({
            ...state,
            fontTablet: e.target.value
        })
    }

    const handleUpdateRighe = (e) => {
        setState({
            ...state,
            righeAgenzia: e.target.value
        })
    }

    const handleUpdatePin = (e) => {
        setState({
            ...state,
            pin: e.target.value
        })
    }

    const handleUpdateHaDomande = (e) => {
        setState({
            ...state,
            haDomande: e.target.checked
        })
    }

    const handleUpdateDomandePin = (e) => {
        setState({
            ...state,
            domandePin: e.target.checked
        })
    }

    const handleUpdateMessaggioAgenzia = (e) => {
        setState({
            ...state,
            messaggioAgenzia: e.target.checked
        })
    }

    const handleSetDeleteAll = () => {
        setDeleteAll(!deleteAll)
    }

    const handleUpdateLanguage = (e) => {
        setState({
            ...state,
            language: e.target.value
        })
    }


    const handleDeleteColonna = (data) => {
        let c = state.colonne
        let index = c.indexOf(data)
        if (index > -1) {
            c.splice(index, 1)
            setState(
                {
                    ...state,
                    colonne: [...c]
                }
            );
        }
    }

    const handleNuovaColonna = (e) => {
        setNuovaColonna(e.target.value)
    }

    const addColonna = () => {
        let c = state.colonne
        c.push(nuovaColonna)
        setNuovaColonna('')
        setState({
            ...state,
            colonne: [...c]
        })
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) =>  {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            state.colonne,
            result.source.index,
            result.destination.index
        );

        setState(
            {
                ...state,
                colonne: items
            }
        );
    }

    return (
        <Dialog open={open} onClose={handleSetEditOpen}>
            <DialogTitle>{t('addConferenza.editTitle')}</DialogTitle>
            <DialogContent>
                <ClearAllForm open={deleteAll} handleClose={handleSetDeleteAll}/>
                <Box sx={{py: 1}}>
                    <Typography fontWeight={'bold'}>{t('addConferenza.general')}</Typography>
                    <SetNome nome={state.nome} handleUpdateNome={handleUpdateNome} language={state.language}
                             handleUpdateLanguage={handleUpdateLanguage}/>
                    <SetColonne colonne={state.colonne} handleDeleteColonna={handleDeleteColonna} onDragEnd={onDragEnd} addColonna={addColonna} handleNuovaColonna={handleNuovaColonna} nuovaColonna={nuovaColonna}/>
                    <SetImage image={state.image} handleUpdateImage={handleUpdateImage}/>
                </Box>
                <Divider/>
                <Box sx={{py: 1}}>
                    <Typography fontWeight={'bold'}>{t('addConferenza.questions')}</Typography>
                    <SetDomande haDomande={state.haDomande} domandePin={state.domandePin}
                                handleUpdateDomandePin={handleUpdateDomandePin}
                                handleUpdateHaDomande={handleUpdateHaDomande}
                                messaggioAgenzia={state.messaggioAgenzia}
                                handleSetMessaggioAgenzia={handleUpdateMessaggioAgenzia}
                                edit={true}
                    />
                </Box>
                <Divider/>
                <Box sx={{py: 1}}>
                    <Typography fontWeight={'bold'}>{t('addConferenza.agency')}</Typography>
                    <SetRighe righe={state.righeAgenzia} handleUpdateRighe={handleUpdateRighe}/>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                    <Button onClick={() => setAdvanced(!advanced)} endIcon={advanced ? <ArrowUpward/> :
                        <ArrowDownward/>}>{t('addConferenza.advanced')}</Button>
                </Box>
                <Collapse in={advanced}>
                    <SetPin pin={state.pin} handleUpdatePin={handleUpdatePin}/>
                    <SetFonts fontTablet={state.fontTablet} fontTabella={state.fontTabella}
                              fontAzienda={state.fontAzienda}
                              handleUpdateFontTablet={handleUpdateFontTablet}
                              handleUpdateFontAzienda={handleUpdateFontAzienda}
                              handleUpdateFontTabella={handleUpdateFontTabella}/>
                </Collapse>
                <Stack direction={"row"} justifyContent={"space-between"} sx={{mt: 2}}>
                    <Button variant={"outlined"} color={"error"} onClick={handleSetDeleteAll}
                            startIcon={<DeleteForever/>}>
                        {t('addConferenza.deleteQuestions')}
                    </Button>
                    <Stack direction={'row'} spacing={2}>
                        <Box>
                            <Button onClick={handleSetEditOpen}>{t('addConferenza.cancel')}</Button>
                        </Box>
                        <Box sx={{position:'relative'}}>
                            <Stack>
                                {loading && <Stack sx={{width: '100%', height: '100%', position: 'absolute'}} justifyContent={'center'} alignItems={'center'}>
                                    <CircularProgress size={30}/>
                                </Stack>}
                            </Stack>
                            <Button disabled={loading} variant={"contained"} onClick={updateConferenza}>{t('addConferenza.save')}</Button>
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default FormEditConferenza
