import React from "react";
import {Button, Dialog, DialogActions, DialogTitle, Stack, Box} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../config";
import {ButtonLoader} from "./shared/ButtonLoader";

const FormCancellaDomanda = ({ handleSetOpen,open,domanda, tipo}) => {
    const {id} = useParams()
    const {t} = useTranslation()
    const [deleting, setDeleting] = React.useState(false)
    const handleDelete = async () => {
        setDeleting(true)
        await axios.delete(`${API_URL}/question/${id}/${domanda}/${tipo === 'rifiutate' ? 'rifiutate' : 'domande'}`)
        setDeleting(false)
    }

    return(
        <Dialog
            open={open}
            onClose={handleSetOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('editDomanda.delete')}
            </DialogTitle>
            <DialogActions>
                <Stack direction={'row'}>
                    <Box>
                        <Button onClick={handleSetOpen} variant={"contained"}>{t('no')}</Button>
                    </Box>
                    <Box sx={{position:'relative'}}>
                        {deleting && <ButtonLoader/>}
                        <Button disabled={deleting} onClick={handleDelete} color='error'>{t('yes')}</Button>
                    </Box>
                </Stack>

            </DialogActions>
        </Dialog>
    )
}

export default FormCancellaDomanda
