import React from "react";
import {Box, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

const SetPin = ({pin, handleUpdatePin}) => {
    const {t} = useTranslation()
    return (
        <Box>
            <InputLabel sx={{mt: 2}} variant="standard">{t('addConferenza.pinNum')}</InputLabel>
            <Select
                fullWidth
                variant={"standard"}
                value={pin}
                onChange={handleUpdatePin}
            >
                <MenuItem value={0}>{t('addConferenza.none')}</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
            </Select>
        </Box>
    )
}

export default SetPin
