import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Box, Card} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

const CurrentQuestionPreview = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    return (
        <Box width={'100%'}>
            <Typography variant={'h4'} fontWeight={'bold'}>{t('currentQuestion')}</Typography>
            <Card variant={'gradient'} sx={{p: 1}}>
                <Box>
                    <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        <iframe
                            src={`https://control.toolpress.app/domanda-attiva/${id}?min=10`}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                            style={{position: 'absolute', top: 0, left: 0,
                                width: '100%', height: '100%'}}
                        ></iframe>
                    </div>
                </Box>
            </Card>
        </Box>
    );
}

export default CurrentQuestionPreview