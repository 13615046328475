import React from "react";
import {CircularProgress, Stack} from "@mui/material";

export const ButtonLoader = ({size = 30}) => {
    return (
        <Stack sx={{width:'100%', height:'100%', position:'absolute'}} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress size={size}/>
        </Stack>
    )
}
