import React, {useState} from "react";
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from "@mui/material";
import {firestore} from "../firebase/clientApp";
import SetNome from "./FormAddConferenza/SetNome";
import SetDomande from "./FormAddConferenza/SetDomande";
import SetRighe from "./FormAddConferenza/SetRighe";
import SetPin from "./FormAddConferenza/SetPin";
import SetFonts from "./FormAddConferenza/SetFonts";
import SetColonne from "./FormAddConferenza/SetColonne";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../config";
import SetImage from "./FormAddConferenza/SetImage";

const FormAddConferenza = ({open, handleSetOpen}) => {
    const {t,i18n} = useTranslation()
    const [nome, setNome] = useState('')
    const [righe, setRighe] = useState(2)
    const [language, setLanguage] = useState(i18n.language)
    const [colonne, setColonne] = useState([t('addConferenza.name'), t('addConferenza.surname')])
    const [pin, setPin] = useState([0])
    const [nuovaColonna, setNuovaColonna] = useState('')
    const [haDomande, setHaDomande] = useState(true)
    const [fontTabella, setFontTabella] = useState(22)
    const [fontAzienda, setFontAzienda] = useState(22)
    const [fontTablet, setFontTablet] = useState(22)
    const [domandePin, setDomandePin] = useState(false)
    const [approvazione, setApprovazione] = useState(true)
    const [messaggioAgenzia, setMessaggioAgenzia] = useState(false)
    const [advanced, setAdvanced] = useState(false)
    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [adding, setAdding] = useState(false)

    const handleUpdateNome = (e) => {
        setNome(e.target.value)
    }

    const handleUpdateImage = (file) => {
        setImageFile(file)
    }

    const handleUpdateHaDomande = (e) => {
        setHaDomande(e.target.checked)
    }

    const handleUpdateDomandePin = (e) => {
        setDomandePin(e.target.checked)
    }

    const handleUpdateApprovazione = (e) => {
        setApprovazione(e.target.checked)
    }

    const handleUpdateRighe = (e) => {
        setRighe(e.target.value)
    }

    const handleUpdatePin = (e) => {
        setPin(e.target.value)
    }

    const handleUpdateFontTabella = (e) => {
        setFontTabella(e.target.value)
    }

    const handleUpdateFontAzienda = (e) => {
        setFontAzienda(e.target.value)
    }

    const handleUpdateFontTablet = (e) => {
        setFontTablet(e.target.value)
    }

    const handleSetMessaggioAgenzia = (e) => {
        setMessaggioAgenzia(e.target.checked)
    }

    const handleUpdateLanguage = (e) => {
        setLanguage(e.target.value)
    }

    const addConferenza = async () => {
        setAdding(true)
        await axios.post(`${API_URL}/conference`, {
            nome: nome,
            righeAgenzia: righe,
            colonne,
            pin: pin,
            fontTabella,
            fontAzienda,
            fontTablet,
            haDomande,
            domandePin: haDomande ? domandePin : false,
            approvazione,
            messaggioAgenzia,
            language,
        })
            .then(async (res) => {
                const formData = new FormData();
                formData.append('file', imageFile);
                if (imageFile !== null) {
                    await axios({
                        url: `${API_URL}/conference/${res.data}/logo`,
                        method: 'POST',
                        data: formData,
                    }).then()
                }
            })
        setAdding(false)
        handleSetOpen()
    }

    const handleDeleteColonna = (data) => {
        let c = colonne
        let index = c.indexOf(data)
        if (index > -1) {
            c.splice(index, 1)
            setColonne([...c]);
        }
    }

    const handleNuovaColonna = (e) => {
        setNuovaColonna(e.target.value)
    }

    const addColonna = () => {
        let c = colonne
        c.push(nuovaColonna)
        setNuovaColonna('')
        setColonne(c)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) =>  {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            colonne,
            result.source.index,
            result.destination.index
        );

        setColonne(items);
    }

    return (
        <Dialog open={open} onClose={handleSetOpen}>
            <DialogTitle>{t('addConferenza.title')}</DialogTitle>
            <DialogContent>
                <Box sx={{py:1}}>
                    <Typography fontWeight={'bold'}>{t('addConferenza.general')}</Typography>
                    <SetNome nome={nome} handleUpdateNome={handleUpdateNome} language={language} handleUpdateLanguage={handleUpdateLanguage}/>
                    <SetColonne colonne={colonne} handleDeleteColonna={handleDeleteColonna} onDragEnd={onDragEnd} addColonna={addColonna} handleNuovaColonna={handleNuovaColonna} nuovaColonna={nuovaColonna}/>
                    <SetImage image={image} handleUpdateImage={handleUpdateImage}/>
                </Box>
                <Divider/>
                <Box sx={{py:1}}>
                    <Typography fontWeight={'bold'}>{t('addConferenza.questions')}</Typography>
                    <SetDomande haDomande={haDomande} domandePin={domandePin} handleUpdateDomandePin={handleUpdateDomandePin} handleUpdateHaDomande={handleUpdateHaDomande} approvazione={approvazione} handleUpdateApprovazione={handleUpdateApprovazione} messaggioAgenzia={messaggioAgenzia} handleSetMessaggioAgenzia={handleSetMessaggioAgenzia}/>
                </Box>
                <Divider/>
                <Box sx={{py:1}}>
                    <Typography fontWeight={'bold'}>{t('addConferenza.agency')}</Typography>
                    <SetRighe righe={righe} handleUpdateRighe={handleUpdateRighe}/>
                </Box>
                <Box sx={{textAlign:'center'}}>
                    <Button onClick={() => setAdvanced(!advanced)} endIcon={advanced ? <ArrowUpward/> : <ArrowDownward/>}>{t('addConferenza.advanced')}</Button>
                </Box>
                <Collapse in={advanced}>
                    <SetPin pin={pin} handleUpdatePin={handleUpdatePin}/>
                    <SetFonts fontTablet={fontTablet} fontTabella={fontTabella} fontAzienda={fontAzienda} handleUpdateFontTablet={handleUpdateFontTablet} handleUpdateFontAzienda={handleUpdateFontAzienda} handleUpdateFontTabella={handleUpdateFontTabella}/>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSetOpen}>{t('addConferenza.cancel')}</Button>
                <Button variant={"contained"} onClick={addConferenza}>{t('addConferenza.add')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormAddConferenza
