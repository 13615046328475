import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import React from "react";
import {DownloadForOffline} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export const GridToolbarButton = ({startIcon, label, onClick, disabled}) => {
    return (
        <Button size="small" startIcon={startIcon} onClick={onClick} disabled={disabled}>
            {label}
        </Button>
    )
}

const CustomToolbar = ({nomeConferenza, buttons = [], totalExport = true}) => {
    const {t} = useTranslation()
    const apiRef = useGridApiContext();
    const handleExport = (options) =>
        apiRef.current.exportDataAsCsv(options);

    return (
        <GridToolbarContainer>
            {<GridToolbarColumnsButton />}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {<GridToolbarExport csvOptions={{
                utf8WithBom: true,
                fileName: `${nomeConferenza} - ${(new Date()).toLocaleString()}`,
            }}/> /** pulsante export di default */}
            {/*<GridToolbarButton
                startIcon={<Download/>}
                label={"Esporta colonne visibili"}
                onClick={handleExport}
            />*/}
            {
                totalExport &&
                    <GridToolbarButton
                        startIcon={<DownloadForOffline/>}
                        label={t("dataGrid.exportAllColumns")}
                        onClick={() => {
                            handleExport({
                                utf8WithBom: true,
                                allColumns: true,
                                fileName: `${nomeConferenza} - ${(new Date()).toLocaleString()}`,
                            })
                        }}
                    />
            }
            {buttons?.map((button, index) => <div key={index}>{button}</div>) /** eventuali buttons aggiuntivi custom */}
        </GridToolbarContainer>
    );
}

export default CustomToolbar
