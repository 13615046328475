import React from "react";
import {Box, InputLabel, Stack, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

const SetFonts = ({fontTabella, handleUpdateFontTabella, fontAzienda, handleUpdateFontAzienda, fontTablet, handleUpdateFontTablet}) => {
    const {t} = useTranslation()
    return (
        <Box>
            <InputLabel sx={{mt: 2}} variant="standard">{t('addConferenza.fontSize')}</InputLabel>
            <Stack direction={"row"} justifyContent={"space-between"} spacing={2} alignItems={"baseline"}>
                <TextField
                    margin="dense"
                    id="image"
                    type="number"
                    label={t('addConferenza.control')}
                    variant="standard"
                    value={fontTabella}
                    onChange={handleUpdateFontTabella}
                />
                <TextField
                    margin="dense"
                    id="image"
                    type="number"
                    variant="standard"
                    label={t('addConferenza.agency')}
                    value={fontAzienda}
                    onChange={handleUpdateFontAzienda}
                />
                <TextField
                    margin="dense"
                    id="image"
                    type="number"
                    variant="standard"
                    label={t('addConferenza.mod')}
                    value={fontTablet}
                    onChange={handleUpdateFontTablet}
                />
            </Stack>
        </Box>
    )
}

export default SetFonts
