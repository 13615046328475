import React, {useState} from "react";
import {Edit} from "@mui/icons-material";
import {Box, IconButton} from "@mui/material";
import {grey} from "@mui/material/colors";
import FormEditDomanda from "./FormEditDomanda";

const EditQuestion = ({domanda, colonne, tipo}) => {
    const [open, setOpen] = useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
    }
    return(
        <Box component={'span'}>
            <IconButton sx={{color: grey[400]}} onClick={handleSetOpen}>
                <Edit/>
            </IconButton>
            <FormEditDomanda tipo={tipo} domanda={domanda} open={open} colonne={colonne} handleSetOpen={handleSetOpen}/>
        </Box>

    )
}

export default EditQuestion
