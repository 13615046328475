import React from "react";
import {Checkbox, FormControlLabel, Stack} from "@mui/material";
import {SetApprovazione} from "./SetApprovazione";
import {useTranslation} from "react-i18next";

const SetDomande = ({haDomande, handleUpdateHaDomande, domandePin, handleUpdateDomandePin, approvazione, handleUpdateApprovazione, edit}) => {
    const {t} = useTranslation()
    return (
        <Stack direction={"column"}>
            <FormControlLabel
                control={
                    <Checkbox checked={haDomande} onChange={handleUpdateHaDomande} name="domande"/>
                }
                label={t('addConferenza.hasQuestions')}
            />
            {((haDomande || approvazione) && !edit) &&
                <SetApprovazione approvazione={approvazione} handleUpdateApprovazione={handleUpdateApprovazione}/>}
            {haDomande && <FormControlLabel
                control={
                    <Checkbox checked={domandePin} onChange={handleUpdateDomandePin} name="domande pin"/>
                }
                label={t('addConferenza.needPin')}
            />}
        </Stack>
    )
}

export default SetDomande
