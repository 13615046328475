import React, {useMemo, useState} from "react";
import {
    Badge,
    Box,
    Chip,
    CircularProgress,
    Dialog,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {Link, useParams} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import CustomGridToolbar from "../components/Reports/CustomGridToolbar";
import useAxios from "axios-hooks";
import {ArrowBackIos, CheckCircle, Delete, Flaky} from "@mui/icons-material";
import {capitalize, filter, orderBy} from "lodash";
import plesh from "../assets/logo_plesh.png";
import {API_URL} from "../config";

const questionTypes = {
    daApprovare: {id: 'daApprovare', label: 'conference.toApprove', icon: <Flaky color={'primary'} sx={{marginRight: 1}} fontSize={'small'}/>,
        color: 'warning'},
    domande: {id: 'domande', label: 'conference.approved', icon: <CheckCircle color={'primary'} sx={{marginRight: 1}} fontSize={'small'}/>,
        color: 'success'},
    rifiutate: {id: 'rifiutate', label: 'conference.refused', icon: <Delete color={'primary'} sx={{marginRight: 1}} fontSize={'small'}/>,
        color: 'error'},

}

const Reports = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    const [pageSize, setPageSize] = useState(10)

    const [dettagliConferenza, loading, error] = useDocument(firestore.doc('tool-press/' + id), {})

    const [{data: allQuestions, loading: loadingQuestions, error: errorQuestions}, fetchQuestions] =
        useAxios(
            {url: `${API_URL}/${id}/questions`},
            { method: "GET", useCache: false, manual: false }
    )

    const [questionType, setQuestionType] = React.useState(null);
    const handleFilterQuestions = (
        event: React.MouseEvent<HTMLElement>,
        newQuestionType,
    ) => {
        setQuestionType(newQuestionType);
    };

    const questions = useMemo(() => {
        if(!allQuestions)
            return null
        else {
            if(!questionType) {
                let res = []
                Object.values(questionTypes).forEach((type) => {
                    res = res.concat(allQuestions.questions[type.id]
                        .map((domanda) => ({
                            ...domanda,
                            type: type.id
                        })))
                })

                return orderBy(res, ['timestamp._seconds'])
            } else {
                return orderBy(allQuestions.questions[questionType].map((domanda) => ({
                    ...domanda,
                    type: questionType
                })), ['timestamp._seconds'])
            }
        }
    }, [allQuestions])

    const filteredQuestions = useMemo(() => {
        if(!questions)
            return []
        else if(!questionType)
            return questions
        return filter(questions, ['type', questionType])
    }, [questions, questionType])

    const columns: GridColDef[] = useMemo(() => {
        if(!dettagliConferenza) {
            return []
        }

        let cols = [
            { field: 'id', headerName: 'ID', width: 70 },
        ]

        cols = cols.concat(
            dettagliConferenza.data().colonne.map((colonna) => ({
                field: colonna,
                headerName: colonna,
                flex: 1,
            }))
        )

        cols = cols.concat([
            {
                field: 'domanda',
                headerName: 'Domanda',
                flex: 2,
            },
            {
                field: 'type',
                headerName: 'Stato',
                flex: 1,
                renderCell: (params) =>
                    <Chip size={'small'}
                          variant={'outlined'}
                          color={questionTypes[params.value].color}
                          label={capitalize(t(questionTypes[params.value].label))}
                          icon={questionTypes[params.value].icon}/>,
                valueFormatter: (params) => {
                    return (t(questionTypes[params.value].label));
                },
            },
            {
                field: 'timestamp',
                headerName: 'Timestamp',
                flex: 1,
                type: 'date-time',
                valueFormatter: (params) => {
                    return (params.value?._seconds ?
                        new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                        : params.value ?
                            new Date(params.value).toLocaleString('it-IT')
                            : "");
                },
            }
        ])

        return cols
    }, [dettagliConferenza])

    return (
        <div>
            <img style={{padding: '2rem', width: '100%', maxWidth: '300px'}} src={plesh}/>
            <Dialog open={loading}>
                <CircularProgress/>
            </Dialog>
            <Box sx={{background:'linear-gradient(90deg, rgba(10,2,36,1) 0%, rgb(15, 3, 54) 100%)', position: {xs:'inherit', md:'sticky'}, top:0, zIndex:'99'}}>
                {!loading &&
                    <Stack sx={{px: 4, pt: 2}}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Box display={'flex'}>
                                <Typography variant={"h4"}>
                                    {t('conference.title')} <span style={{fontWeight: 'bold'}}>{dettagliConferenza.data().nome}</span>
                                </Typography>
                            </Box>
                            <ToggleButtonGroup
                                value={questionType}
                                exclusive
                                size={'small'}
                                onChange={handleFilterQuestions}
                                aria-label="question type"
                            >
                                {Object.values(questionTypes).map((type, i) => (
                                    <ToggleButton key={i} value={type.id}>
                                        {/*type.icon*/}
                                        {`${t(type.label)}`}
                                        <Badge
                                            badgeContent={<strong>{filter(questions, ['type', type.id]).length}</strong>}
                                            color={type.color}
                                            sx={{pl: 2, mr: 1}}
                                        />
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Stack>
                        <Box mt={3} height={650}>
                            <DataGrid
                                loading={loadingQuestions}
                                disableSelectionOnClick
                                onRowClick={(params, event, details) => {
                                    //navigate("/user/" + params.row.id);
                                    // TODO: Show row details
                                }}
                                components={{
                                    Toolbar: () => {
                                        return <CustomGridToolbar nomeConferenza={dettagliConferenza.data().nome}/>
                                    }
                                }}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[10, 20, 50]}
                                columns={columns}
                                rows={filteredQuestions}
                                //checkboxSelection={true}
                            />
                        </Box>
                    </Stack>
                }
            </Box>
        </div>
    );
}

export default Reports
