import React, {useState} from "react";
import {Box, Button, CardActionArea, Stack} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {AddCircle} from "@mui/icons-material";
import FormAddConferenza from "./FormAddConferenza";
import {useTranslation} from "react-i18next";

const AddConferenza = () => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
    }

    return(
        <Box>
            <Button variant={"contained"} startIcon={<AddCircle/>} onClick={() => setOpen(true)}>
                Aggiungi una conferenza
            </Button>
            <FormAddConferenza open={open} handleSetOpen={handleSetOpen}/>
        </Box>
    )
}

export default AddConferenza
