import React from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogTitle, Stack, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../config";

const FormCancellaConferenza = ({nome, handleSetOpen,open,id}) => {
    const {t} = useTranslation()
    const [loading, setLoading] = React.useState(false)
    const handleDelete = async () => {
        setLoading(true)
        await axios.delete(`${API_URL}/conference/${id}`, {})
        setLoading(false)
    }

    return(
        <Dialog
            open={open}
            onClose={handleSetOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('eliminaConferenza.title')+' '+nome+'?'}
            </DialogTitle>
            <DialogActions>
                <Stack direction={'row'} spacing={1}>
                    <Box>
                        <Button onClick={handleSetOpen} variant={"contained"}>{t('no')}</Button>
                    </Box>
                    <Box sx={{position:'relative'}}>
                        {loading && <Stack sx={{width: '100%', height: '100%', position: 'absolute'}} justifyContent={'center'} alignItems={'center'}>
                            <CircularProgress size={30}/>
                        </Stack>}
                        <Button disabled={loading} onClick={handleDelete} color='error'>{t('yes')}</Button>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

export default FormCancellaConferenza
