import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Link, useNavigate} from "react-router-dom";
import logo from '../assets/logo_toolpress.png'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {auth} from "../firebase/clientApp";
import {Home, Logout} from "@mui/icons-material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {LangSwitch} from "./LangSwitch";

const Header = () => {
    const navigate = useNavigate()
    const [confirm, setConfirm] = useState(false)
    const {t,i18n} = useTranslation()
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const logOut = () => {
        auth.signOut().then(() => {
            navigate('/login', {replace:true})
        }).catch((error) => {
            // An error happened.
        });
    }

    return (
        <AppBar position="static" sx={{border:'none'}}>
            <Toolbar variant={'dense'}>
                <Stack direction={"row"} sx={{width:'100%'}} justifyContent={"space-between"} alignItems={"center"}>
                    <Link to={'/'}>
                        {!md ?
                            <IconButton>
                                <Home/>
                            </IconButton>
                            :
                            <img src={logo} style={{maxWidth:'15rem', padding:'0.7rem', marginTop:'4px'}}/>
                        }
                    </Link>
                    <Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"}>
                        <Typography>{t('lang')}</Typography>
                        <LangSwitch/>
                        <IconButton onClick={() => setConfirm(true)}>
                            <Logout/>
                        </IconButton>
                    </Stack>
                </Stack>
            </Toolbar>
            <Dialog
                open={confirm}
                onClose={() => setConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("logout.title")}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setConfirm(false)} variant={"contained"}>{t('no')}</Button>
                    <Button onClick={logOut} color='error'>{t('yes')}</Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    );
};
export default Header;
