import React, {useState} from "react";
import {DeleteForever} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import FormCancellaDomanda from "./FormCancellaDomanda";

const DeleteQuestion = ({domanda, updatePosition, tipo}) => {
    const [open, setOpen] = useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
    }
    return(
        <span>
            <IconButton color={'error'} onClick={handleSetOpen}>
                <DeleteForever/>
            </IconButton>
            <FormCancellaDomanda tipo={tipo} domanda={domanda} open={open} handleSetOpen={handleSetOpen}/>
        </span>

    )
}

export default DeleteQuestion
